import React, { ReactElement } from 'react';
import { Center, Box } from '@chakra-ui/react';
import { Heading } from '../components/Heading';

const Page404 = (): ReactElement => (
  <Center minHeight={'calc(100vh - 8em)'}>
    <Box backgroundColor={'tealCarnation'} padding={'10%'} textAlign={'center'}>
      <Heading level={1} color={'white'} margin={0}>
        404
      </Heading>
    </Box>
  </Center>
);

export default Page404;
